import Company from '@/components/PromotionAdmin/DataEdit/Company/Company';
import styled from 'styled-components';

function CompanyPage() {
  return (
    <>
      <Company />
    </>
  );
}

export default CompanyPage;
